* {
  font-family: "Nunito", sans-serif;
  --primary-color: #1d71b7 !important;
  --secondary-color: #039b9c !important;
  --thirty-color: #eeeeee !important;
  box-sizing: border-box;
}

html {
  background-color: var(--thirty-color);
}

.Auth {
  background-image: url("images/background-signin.jpg");
  background-size: cover;
  background-position: 50% 75%;
}

.background-test {
  background-image: url("images/test.png");
  background-size: 100% 100%;
  padding-left: 70px;
  padding-top: 180px;
  padding-bottom: 120px;
  padding-right: 140px;
}

.opacity:hover {
  opacity: 0.7;
  transition: 0.3s;
}

.Dashboard-content-image {
  background-size: cover;
}

.Auth-container {
  background-color: var(--white) !important;
  border-radius: 15px;
  padding: 40px;
}

@media screen and (min-width: 320px) {
  .Dashboard-content-image {
    background-image: url("images/fond-320.jpg");
  }
}
@media screen and (min-width: 600px) {
  .Dashboard-content-image {
    background-image: url("images/fond-600.jpg");
  }
}
@media screen and (min-width: 900px) {
  .Dashboard-content-image {
    background-image: url("images/fond-900.jpg");
  }
}
@media screen and (min-width: 1366px) {
  .Dashboard-content-image {
    background-image: url("images/fond-1366.jpg");
  }
}
@media screen and (min-width: 1920px) {
  .Dashboard-content-image {
    background-image: url("images/fond-1920.jpg");
  }
}

.Dashboard-container-special .Dashboard-content {
  padding: 0 !important;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  background-color: black;
}

.Dashboard-container-special .Dashboard-container-body > div:first-child > div {
  border-radius: 0;
  z-index: 1;
}

.Dashboard-container-special .disabled-column {
  opacity: 0.4;
  cursor: not-allowed;
  transition: 0.3s;
}

.Dashboard-container-special .enabled-column {
  cursor: pointer;
}
.Dashboard-container-special .enabled-column:hover {
  opacity: 0.9;
}
.Dashboard-container-special .enabled-column:active {
  opacity: 0.7;
}

.tool-item-container {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 12px;
  width: 100%;
  height: 100px;
  margin-bottom: 16px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  cursor: pointer;
  transition: 0.3s;
}

.tool-item-container:hover:not(.disabled) {
  opacity: 0.7;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.tool-item-container.disabled {
  cursor: not-allowed;
  opacity: 0.1;
}

.tool-item-container > div {
  position: absolute;
  inset: 0px;
  background-image: url("images/background.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 10;
  pointer-events: none;
  opacity: 1;
}

.tool-item-container > img {
  margin: auto;
  margin-bottom: 8px;
  height: 26px;
}

.tool-item-container > span {
  margin: auto;
  margin-top: 0;
  font-weight: bold;
}

.animation-opacity {
  transition: 0.3s;
}

.animation-opacity:hover:not(.disabled) {
  opacity: 0.7;
}

.animation-opacity:active:not(.disabled) {
  opacity: 0.7;
}
